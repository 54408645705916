'use client'

import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
export interface ShowAfterFirstRenderProps {
  children: React.ReactNode
}

export default function ShowAfterFirstRender({ children }: ShowAfterFirstRenderProps) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <div className={cn('transition-all translate-y-10 opacity-0 duration-500 w-full', show && 'opacity-100 translate-y-0 ')}>
      {children}
    </div>
  )
}
